.conf-desktop-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto min-content;
  /*  min-height: 800;*/
  align-content: center;
  align-items: center;
}

.conf-desktop-stage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;
  gap: 8px;
  overflow: hidden;
  align-content: center;
}

.conf-desktop-stage-center {
  grid-column: 1 / 11;
  grid-row: 100%;
}

.conf-desktop-sidebar {
  grid-column: 11 / 13;
  display: grid;
  gap: 8px;
  grid-auto-rows: min-content;
  overflow-y: scroll;
}

.conf-desktop-controls-area {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 65px;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: rgba(60, 60, 60, 0.5);
}

.conf-desktop-grid-stage {
  display: grid;
  gap: 8px;
  padding: 8px;
  overflow: hidden;
  height: 100%;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
}

.conf-desktop-grid1x1 {
  grid-template-columns: auto;
  grid-template-rows: 100%;
}

.conf-desktop-grid2x1 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

.conf-desktop-grid1x2 {
  grid-template-columns: 1fr;
  grid-template-rows: 50% 50%;
  /*  grid-template-rows: repeat(2, 1fr);*/
}


.conf-desktop-grid2x2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50% 50%;
  /*grid-template-rows: repeat(2, 1fr);*/
}

.conf-desktop-grid3x2 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 50% 50%;
  /*  grid-template-rows: repeat(2, 1fr);*/
}

.conf-desktop-grid2x3 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 33% 33% 34%;
  /*  grid-template-rows: repeat(3, 1fr);*/
}

.conf-desktop-grid3x3 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 33% 33% 34%;
  /*  grid-template-rows: repeat(3, 1fr);*/

}

.conf-desktop-grid4x4 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 25% 25% 25% 25%;
  /*  grid-template-rows: repeat(3, 1fr);*/
}

.conf-desktop-grid5x5 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 25% 25% 25% 25%;
  /*  grid-template-rows: repeat(3, 1fr);*/
}