.conf-layout-center {
  height: calc(100vh - 100px);
  /* make sure you have space betwen values */
  height: -o-calc(100vh - 110px);
  /* opera */
  height: -webkit-calc(100vh - 110px);
  /* google, safari */
  height: -moz-calc(100vh - 110px);
  /* firefox */
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';

}


video.conf-video {
  object-position: center;
  border-radius: 4px;
  min-height: 0;
  min-width: 0;
}

.conf-participant {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.conf-placeholder {
  padding: 100;
  background: #545454;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
}

.conf-participant-bar {
  box-sizing: border-box;
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 6px 8px 6px 8px;
  font-weight: 500;
  font-size: 16px;
  display: grid;
  grid-template-columns: auto 1fr min-content;
  justify-content: space-evenly;
}

.conf-participant-bar .conf-name {
  white-space: nowrap;
  justify-self: center;
}

.conf-joined {
  padding: 3px;
  font-size: 15px;
  font-weight: 500;
  justify-self: center;
  text-align: center;
}

.conf-name1 {
  white-space: nowrap;
  justify-self: center;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
}

.conf-participant-bar .conf-start {
  justify-self: left;
}

.conf-participant-bar .conf-center {
  justify-self: center;
}

.conf-participant-bar .conf-end {
  justify-self: right;
}

.conf-participant-bar .conf-stats {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
}

.conf-screen-share {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: calc(100vh - 120px);
  /* make sure you have space betwen values */
  height: -o-calc(100vh - 120px);
  /* opera */
  height: -webkit-calc(100vh - 120px);
  /* google, safari */
  height: -moz-calc(100vh - 120px);
  /* firefox */

}

.conf-screen-share-top {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: calc(100vh - 260px);
  /* make sure you have space betwen values */
  height: -o-calc(100vh - 260px);
  /* opera */
  height: -webkit-calc(100vh - 260px);
  /* google, safari */
  height: -moz-calc(100vh - 260px);
  /* firefox */
  border: '1px solid red';
}

.conf-icon-red {
  color: #981010;
}


.conf-button-wrapper {
  display: inline-block;
  margin: 4px;
  white-space: nowrap;
}

.conf-button {
  display: inline-block;
  border-radius: 44px;
  border: none;
  background: #2F2F2F;
  padding: 12px 6px 12px 12px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}

.conf-button-textonly {
  display: inline-block;
  border-radius: 44px;
  border: none;
  background: #2F2F2F;
  padding: 12px 12px 12px 12px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}

.join-button {
  background: #4656cc;
}

.leave-button {
  background: #dc143c;
}

.conf-red-button {
  background: #dc143c;
}

.conf-red-button:hover {
  background: #ef1f0c;
}

.conf-button:active {
  border: none;
}

.conf-button:hover {
  background: #434343;
}

.conf-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.conf-button .conf-icon {
  display: inline-block;
  margin-right: 8px;
}

button.conf-danger-button {
  background: #981010;
}

button.conf-danger-button:hover {
  background: #b81212;
}

button.conf-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 12px;
  padding-right: 15px;
  margin-left: 0;
}

button.conf-has-dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 4px;
  margin-right: 0;
}

.conf-separator {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 8px;
  margin-left: -12px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 20px;
}

.conf-popover-menu {
  background: #2F2F2F;
  border-radius: 4px;
  margin-bottom: 5px;
}

ul.conf-list {
  border: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-inline: 0;
}

ul.conf-list li {
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
}

ul.conf-list li:first-child {
  border: 0;
}

.conf-container {
  width: 100%;
  max-height: calc(100vh - 110px);
  /* make sure you have space betwen values */
  max-height: -o-calc(100vh - 110px);
  /* opera */
  max-height: -webkit-calc(100vh - 110px);
  /* google, safari */
  max-height: -moz-calc(100vh - 110px);
  position: relative;
}

.conf-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conf-unmute-button {
  display: block;
  background-color: #5A8BFF;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  color: white;
  font-weight: 700;
  filter: drop-shadow(3px 7px 13px rgba(0, 0, 0, 0.17));
  border-radius: 8px;
  text-transform: uppercase;
}

.conf-unmute-button:active {
  border: none;
}

.conf-unmute-button .conf-icon {
  display: inline-block;
  margin-right: 12px;
}