.chat-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 320px;
    align-items: 'right';
    display: 'flex';
    justify-content: center;
    padding: 0px 0px 0px 10px;
    height: calc(100vh - 100px);
    background-color: #000000;
    color: #e8e4e4cd;
}

.chat-container-light {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 320px;
    align-items: 'right';
    display: 'flex';
    justify-content: center;
    padding: 0px 0px 0px 10px;
    height: calc(100vh - 100px);
    background-color: #ffffff;
    color: #2d2c2ccd;

}

/* This is the chat content showing the bubble messages*/
.chat {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-content {
    padding: 8px;
    /*    height: calc(100% - 145px);*/
    flex: 1 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.chat-content::-webkit-scrollbar {
    display: none;
}

/* This is the chat text box section */
.chat-content-message-bottom {
    clear: both;
}

.chat-new-message {
    display: flex;
    padding: 10px;
    flex: 0 0 min-content;
}

.chat-new-message-textbox {
    width: 100%;
    color: #000000;
    height: 30px;
    border-radius: 10px;
    padding: 10px;
}

.chat-container-light.chat-new-message-textbox {
    width: 100%;
    border-color: #666;
    color: #000000;
    height: 30px;
    border-radius: 10px;
    padding: 10px;
}

.chat-new-message-button {
    background-color: #2c5bd3;
    color: #ffffff;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.chat-new-message-button:hover {
    color: #2b43ce;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
}


.chat-content:after {
    content: "";
    display: block;
    clear: both;
}

.chat-content-self-message {
    float: right;
    clear: both;
    max-width: 250px;
    width: fit-content;
    margin: 12px 0px 12px 10px;
}

.chat-bubble-self {
    word-wrap: break-word;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
    width: '100%';
    color: #000000;
    font-size: 1.2em;
    font-weight: 500;
    min-width: 50px;
    position: relative;
    background-color: #324de5;
    border-radius: 8px;
    color: #ffffff;
}

.chat-content-timebar {
    width: 100%;
    text-align: center;
    clear: both;
    padding-top: 28px;
    font-weight: 600;
}

.chat-content-other {
    margin: 0px 0px 10px 0px;
    float: left;
    clear: both;
}

.chat-bubble-other {
    word-wrap: break-word;
    background-color: #d6d3d1ef;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
    width: fit-content;
    max-width: 250px;
    color: #000000;
    font-size: 1.2em;
    font-weight: 500;
    min-width: 50px;
    display: inline-block;
    position: relative;
}

.chat-content-other-name {
    margin: 5px;
}

.chat-content-other-message {
    background-color: #d6d3d1ef;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    color: #000000;
    font-size: 1.2em;
    font-weight: 500;
}

/* container */
.container {
    padding: 5% 5%;
}

.border {
    border: 8px solid #666;
}

.round {
    border-radius: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;

}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 35px;
    right: auto;
    top: auto;
    bottom: -25px;
    border: 10px solid;
    border-color: #d6d3d1ef transparent transparent #d6d3d1ef;
    z-index: 3;
}

.tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 12px;
    right: auto;
    top: auto;
    bottom: -10px;
    border: 5px solid;
    border-color: #d6d3d1ef transparent transparent #d6d3d1ef;
    z-index: 3;

}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 30px;
    bottom: -25px;
    border: 10px solid;
    border-color: #324de5 #324de5 transparent transparent;
}

.tri-right.btm-right-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 8px;
    bottom: -10px;
    border: 5px solid;
    border-color: #324de5 #324de5 transparent transparent;
}