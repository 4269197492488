.conf-mobile-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto min-content min-content;
  min-height: 0;
  row-gap: 8px;
}

.conf-mobile-stage {
  display: grid;
  grid-row: 100%;
  overflow: auto
}

.conf-mobile-participants-area {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.conf-mobile-participants-area::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.conf-mobile-participant {
  flex-shrink: 0;
  width: 180px;
}

.conf-mobile-controls-area {
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
}